<template>
  <section>
    <div style="height: 95vh !important;" class="primary">
      <v-img src="/img/welcome-background.jpg" height="95vh">
        <v-overlay :absolute="true" :opacity="0" z-index="4" class="insetShadow">
          <v-flex align-center>
            <div>
              <div class="hidden-sm-and-down">
                <div class="white--text text-center mt-n16">
                  <div class="titleLogo">
                    SALT & SEA
                  </div>
                  <div class="subtitleLogo mt-n14">
                    SCUBA
                  </div>
                </div>
              </div>
              <div class="hidden-md-and-up">
                <div class="white--text text-center mt-n16">
                  <div class="titleLogoMobile">
                    SALT & SEA
                  </div>
                  <div class="subtitleLogoMobile mt-n6">
                    SCUBA
                  </div>
                </div>
              </div>
              <div class="turtleAnimation">
                <v-img ref="turtleAnimation" src="/img/turtle_alpha.gif" :style="`position: absolute; bottom: ${-80 + (scrollY / 40)}px; right: ${0 + (scrollY / 30)}px`" rel:animated_src="/img/turtle_alpha.gif" rel:auto_play="0" rel:rubbable="0"></v-img>
              </div>
            </div>
          </v-flex>
        </v-overlay>
      </v-img>
    </div>
    <v-container class="pt-0">
      <div class="pa-2 hidden-md-and-down"></div>
      <div>
        <v-row class="mt-2 hidden-sm-and-down">
          <v-col cols="4">
            <v-card elevation="0">
              <v-card-text class="pa-0 rounded-lg">
                <retail-images image-name="front-desk" img-class="rounded-lg">
                  <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="retailInfo insetShadow">
                    <v-flex align-center>
                      <div class="text-center justify-center align-center mb-2">
                        <div class="hidden-md-and-down">
                          <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/padi-logo.png" max-width="64" contain></v-img>
                          <div class="gothic-2 mt-3">PADI 5 Star <br>IDC Development Center</div>
                          <v-rating
                            color="#FFD700"
                            full-icon="fa-solid fa-star"
                            hover
                            length="5"
                            readonly
                            size="15"
                            :value="5"
                          ></v-rating>
                        </div>
                        <div class="hidden-lg-and-up">
                          <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/padi-logo.png" max-width="46" contain></v-img>
                          <div class="gothic-2 mt-1" style="font-size: 22px">PADI 5 Star <br>IDC Development Center</div>
                        </div>
                      </div>
                    </v-flex>
                  </v-overlay>
                  <div class="retailInfoButton">
                    <div class="ma-3 text-center">
                      <outlined-white-button @click="doGoToAbout">Learn more</outlined-white-button>
                    </div>
                  </div>
                </retail-images>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="0">
              <v-card-text class="pa-0 rounded-lg">
                <retail-images image-name="indoor-pool-1" img-class="rounded-lg">
                  <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="retailInfo insetShadow">
                    <v-flex align-center>
                      <div class="text-center justify-center align-center">
                        <div class="hidden-md-and-down">
                          <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/diving-gear-white.png" max-width="52" contain></v-img>
                          <div class="gothic-2 mt-4">Dive In. <br>Unleash Adventure!</div>
                        </div>
                        <div class="hidden-lg-and-up">
                          <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/diving-gear-white.png" max-width="46" contain></v-img>
                          <div class="gothic-2 mt-3" style="font-size: 22px">Dive In. <br>Unleash Adventure!</div>
                        </div>
                      </div>
                    </v-flex>
                  </v-overlay>
                  <div class="retailInfoButton">
                    <div class="ma-3 text-center">
                      <outlined-white-button @click="doGoToDiscoverScuba">Discover Scuba</outlined-white-button>
                    </div>
                  </div>
                </retail-images>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="0">
              <v-card-text class="pa-0 rounded-lg">
                <retail-images image-name="store-equipment-1" img-class="rounded-lg">
                  <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="retailInfo insetShadow">
                    <v-flex align-center>
                      <div class="text-center justify-center align-center">
                        <div class="hidden-md-and-down">
                          <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/fins-white.png" max-width="64" contain></v-img>
                          <div class="gothic-2 mt-3">Premier Equipment</div>
                        </div>
                        <div class="hidden-lg-and-up">
                          <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/fins-white.png" max-width="46" contain></v-img>
                          <div class="gothic-2 mt-3" style="font-size: 22px">Premier Equipment</div>
                        </div>
                      </div>
                    </v-flex>
                  </v-overlay>
                  <div class="retailInfoButton">
                    <div class="ma-3 text-center">
                      <outlined-white-button @click="doGoToEquipment">Check it out</outlined-white-button>
                    </div>
                  </div>
                </retail-images>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
        <div class="hidden-md-and-up expanded">
          <HorizontalLineDivider class="primary mb-0"></HorizontalLineDivider>
          <v-carousel hide-delimiters cycle continuous :interval="5000" :show-arrows="false">
            <v-carousel-item>
              <retail-images image-name="front-desk" height="100%" style="height: 100%;">
                <v-overlay :absolute="true" :opacity="0.7" z-index="4" class="insetShadow">
                  <v-flex align-center>
                    <div class="text-center justify-center align-center">
                      <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/padi-logo.png" max-width="46" contain></v-img>
                      <div class="gothic-2 mt-1" style="font-size: 22px">PADI 5 Star <br>IDC Development Center</div>
                      <v-rating
                        class="mt-1"
                        color="#FFD700"
                        full-icon="fa-solid fa-star"
                        hover
                        length="5"
                        readonly
                        size="16"
                        :value="5"
                      ></v-rating>
                      <outlined-white-button class="mt-2" @click="doGoToAbout">Learn more</outlined-white-button>
                    </div>
                  </v-flex>
                </v-overlay>
              </retail-images>
            </v-carousel-item>
            <v-carousel-item>
              <retail-images image-name="indoor-pool-1" height="100%" style="height: 100%;">
                <v-overlay :absolute="true" :opacity="0.7" z-index="4" class="insetShadow">
                  <v-flex align-center>
                    <div class="text-center justify-center align-center">
                      <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/diving-gear-white.png" max-width="52" contain></v-img>
                      <div class="gothic-2 mt-2" style="font-size: 22px">Dive In. Unleash Adventure!</div>
                      <outlined-white-button class="mt-2" @click="doGoToDiscoverScuba">Discover Scuba</outlined-white-button>
                    </div>
                  </v-flex>
                </v-overlay>
              </retail-images>
            </v-carousel-item>
            <v-carousel-item>
              <retail-images image-name="store-equipment-1" height="100%" style="height: 100%;">
                <v-overlay :absolute="true" :opacity="0.7" z-index="4" class="insetShadow">
                  <v-flex align-center>
                    <div class="text-center justify-center align-center">
                      <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/fins-white.png" max-width="64" contain></v-img>
                      <div class="gothic-2 mt-1" style="font-size: 22px">Premier Equipment</div>
                      <outlined-white-button class="mt-2" @click="doGoToEquipment">Check it out</outlined-white-button>
                    </div>
                  </v-flex>
                </v-overlay>
              </retail-images>
            </v-carousel-item>
          </v-carousel>
          <HorizontalLineDivider class="primary mb-0"></HorizontalLineDivider>
        </div>
      </div>
      <div  v-if="!courses.isEmpty" class="pa-6 hidden-md-and-down"></div>
      <div v-if="!courses.isEmpty" class="mt-2">
        <div v-if="courses.isLoading">
            <v-progress-linear rounded indeterminate height="10" class="my-10"></v-progress-linear>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12" lg="4">
              <div class="rounded-lg hidden-sm-and-down no-select" style="position: relative;">
                <v-carousel hide-delimiters :cycle="true" :show-arrows="false" class="primaryCourse rounded-lg mx-auto">
                  <v-carousel-item v-for="(image, index) in courses.data[0].images" :src="image.url" :key="index"></v-carousel-item>
                </v-carousel>
                <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="primaryCourse insetShadow">
                  <div style="position: relative; width: 100%; height: 100%;">
                    <div>
                      <v-avatar size="58" class="ma-2">
                        <v-img contain :src="courses.data[0].organization.logoUrl"></v-img>
                      </v-avatar>
                      <span class="font-weight-bold">{{ courses.data[0].organization.name }}</span>
                    </div>
                    <div class="pa-4" style="position: absolute; top: 0; right: 0;">
                      <outlined-white-button @click="doGoToCourse(courses.data[0].id)">Explore</outlined-white-button>
                    </div>
                    <div class="pa-4" style="position: absolute; bottom: 0;">
                      <div class="goBold-4 mt-1 mb-3">{{ courses.data[0].name }}</div>
                      <div class="gothic-1 mt-1">{{ courses.data[0].subtitle }}</div>
                    </div>
                  </div>
                </v-overlay>
              </div>
            </v-col>
            <v-col cols="12" lg="8">
              <div>
                <div class="mt-2 mb-9 pa-2 text-center gothic-4">Our top courses</div>
                <top-carousel-list class="expanded" :courses="courses.data.slice(1, courses.data.length >= 10 ? 10 : courses.data.length)"></top-carousel-list>
              </div>
            </v-col>
          </v-row>

        </div>
      </div>
      <div class="pa-6 hidden-md-and-down"></div>
      <div class="rounded-lg mt-2 expanded" style="height: 400px;">
        <retail-images image-name="open-water-instructor" height="400" img-class="remove-border-radius-md rounded-lg">
          <v-overlay absolute opacity="0.5">
            <div class="white--text pa-4 text-center" style="height: 400px;">
              <div>
                <v-avatar size="58" class="ma-2">
                  <v-img contain src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/padi-logo.png" alt="PADI Logo"></v-img>
                </v-avatar>
                <div class="font-weight-bold antonio-2 mt-2">PADI</div>
              </div>
              <div class="goBold-3">Instructor Development Course</div>
              <div class="goBold-2 mt-4">Coming in late 2023 and early 2024</div>
            </div>
            <div class="pa-4" style="position:absolute; bottom: 0; left:0; right:0;">
              <div class="my-3">
                <outlined-white-button text tile outlined block @click="doCallBusiness" aria-label="Call for Questions">
                  <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
                  <span class="ml-2">Call for inquiry</span>
                </outlined-white-button>
              </div>
            </div>
          </v-overlay>
        </retail-images>
      </div>
      <div v-if="!trip.isEmpty" class="pa-6 hidden-md-and-down"></div>
      <div v-if="!trip.isEmpty" class="mt-2">
        <div v-if="trip.isLoading">
            <v-progress-linear rounded indeterminate height="10" class="my-10"></v-progress-linear>
        </div>
        <div v-else>
          <span v-if="trip.data.dates.length === 1">
              <quick-info-action class="trip-info rounded-lg" :title="trip.data.name" :description="`Join us on a ${trip.data.dates[0].nights} Night trip. Reserve your spot today!`" :route-to-name="'view-trip'" :route-params="{ id: trip.data.id }" :poster="trip.data.images[0].url"></quick-info-action>
          </span>
          <span v-else>
              <quick-info-action class="trip-info rounded-lg" :title="trip.data.name" :description="'Join us on a trip of a lifetime!'" route-to-name="view-trip" :route-params="{ id: trip.data.id }" :poster="trip.data.images[0].url"></quick-info-action>
          </span>
        </div>
      </div>
      <div class="pa-6 hidden-md-and-down"></div>
      <div class="rounded-lg mt-2 expanded" style="height: 400px;">
        <retail-images image-name="padi-club" height="400" img-class="remove-border-radius-md rounded-lg">
          <v-overlay absolute opacity="0.4">
            <div class="white--text pa-4 text-center" style="height: 400px;">
              <div class="goBold-3">Make the Connection</div>
              <div class="mt-4">
                <v-img contain width="175" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/brands/padi-club.gif" alt="PADI Club"></v-img>
              </div>
            </div>
            <div class="pa-4" style="position:absolute; bottom: 0; left:0; right:0;">
              <outlined-white-button block @click="doOpenExternalUrl('https://www.padi.com/padiclub?irra=28411&irrap=club')">Learn more</outlined-white-button>
            </div>
          </v-overlay>
        </retail-images>
      </div>
      <div v-if="!teamMembers.isEmpty" class="pa-6 hidden-md-and-down"></div>
      <div v-if="!teamMembers.isEmpty" class="mt-2">
        <div v-if="teamMembers.isLoading">
          <v-progress-linear rounded indeterminate height="10" class="my-10"></v-progress-linear>
        </div>
        <div v-else>
          <div class="mt-2">
            <div class="hidden-md-and-up py-3"></div>
            <div v-intersect="onLogoIntersect"></div>
            <div class="mt-4 mb-9 pa-2 text-center gothic-4">This is
              <div class="white--text text-center">
                <div ref="logoAnimation" class="logo-background-image titleLogo" style="font-size: 52px; !important;">
                  SALT & SEA
                  <div class="subtitleLogo mt-n2" style="font-size: 32px !important;">
                    SCUBA
                  </div>
                </div>
              </div>
            </div>
            <v-row>
              <v-col cols="12" lg="5" order="2" order-lg="1">
                <div class="d-flex align-center fill-height">
                  <v-carousel hide-delimiters cycle :show-arrows="false" continuous :interval="8000" class="fill-height">
                    <v-carousel-item v-for="(teamMember, i) in teamMembers.data" :key="i">
                      <div class="rounded-lg fill-height d-flex align-center">
                        <v-img class="rounded-lg hidden-lg-and-down" :aspect-ratio="1.5" :src="teamMember.image.url">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate></v-progress-circular>
                            </v-row>
                          </template>
                          <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="teamMemberCard insetShadow">
                            <div style="position: relative; width: 100%; height: 100%;">
                              <div class="pa-4" style="position: absolute; bottom: 0;">
                                <div class="goBold-4 mt-1 mb-3">
                                  <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.publicName }}</p>
                                </div>
                                <div class="antonio-2 mt-1 mb-3">
                                  <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.title }}</p>
                                </div>
                              </div>
                            </div>
                          </v-overlay>
                        </v-img>
                        <v-img class="rounded-lg hidden-md-only hidden-sm-only hidden-xl-only" :aspect-ratio="1" :src="teamMember.image.url">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate></v-progress-circular>
                            </v-row>
                          </template>
                          <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="teamMemberCard insetShadow">
                            <div style="position: relative; width: 100%; height: 100%;">
                              <div class="pa-4" style="position: absolute; bottom: 0;">
                                <div class="goBold-4 mt-1 mb-3">
                                  <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.publicName }}</p>
                                </div>
                                <div class="antonio-2 mt-1 mb-3">
                                  <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.title }}</p>
                                </div>
                              </div>
                            </div>
                          </v-overlay>
                        </v-img>
                        <v-img class="rounded-lg hidden-lg-and-up hidden-xs-only" :aspect-ratio="1.75" :src="teamMember.image.url">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate></v-progress-circular>
                            </v-row>
                          </template>
                          <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="teamMemberCard insetShadow">
                            <div style="position: relative; width: 100%; height: 100%;">
                              <div class="pa-4" style="position: absolute; bottom: 0;">
                                <div class="goBold-4 mt-1 mb-3">
                                  <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.publicName }}</p>
                                </div>
                                <div class="antonio-2 mt-1 mb-3">
                                  <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.title }}</p>
                                </div>
                              </div>
                            </div>
                          </v-overlay>
                        </v-img>
                      </div>
                    </v-carousel-item>
                  </v-carousel>
                </div>
              </v-col>
              <v-col cols="12" lg="7" order="1" order-lg="2">
                <div>
                    <v-row>
                      <v-col>
                        <bento-box-card style="min-height: 156px;">
                          <div>
                            <div class="goBold-2 black--text">12ft deep onsite</div>
                            <font-awesome-icon :icon="['fas', 'fire']" size="2xl" class="deep-orange--text my-2 mt-4"/>
                            <div class="goBold-4 background-text heated-pool">heated pool</div>
                          </div>
                        </bento-box-card>
                      </v-col>
                      <v-col>
                        <bento-box-card style="min-height: 156px;">
                          <div class="background-text heated-pool">
                            <div class="goBold-2">astounding</div>
                            <div class="goBold-3 primary--text drop-font-md">instructors</div>
                            <div class="goBold-2">leading to success</div>
                          </div>
                        </bento-box-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <bento-box-card style="min-height: 156px;">
                          <div class="background-text heated-pool">
                            <div class="goBold-3 drop-font-md">60 years</div>
                            <div class="goBold-2 black--text drop-font-sm">of combined</div>
                            <div class="goBold-3 drop-font-md">experience</div>
                          </div>
                        </bento-box-card>
                      </v-col>
                      <v-col>
                        <bento-box-card style="min-height: 156px;">
                          <font-awesome-icon :icon="['fas', 'anchor']" size="4x" class="primary--text my-2 mt-4"/>
                          <div class="goBold-2 background-text store-equipment mt-2 ">premier equipment</div>
                        </bento-box-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <bento-box-card style="min-height: 156px;">
                          <font-awesome-icon :icon="['fas', 'wand-magic-sparkles']" size="4x" class="primary--text my-2 mt-4"/>
                          <div class="goBold-3 background-text store-equipment mt-2 ">and much more!</div>
                          <outlined-auto-button class="mt-3" @click="doGoToAbout" block>Learn more!</outlined-auto-button>
                        </bento-box-card>
                      </v-col>
                    </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div class="pa-2 hidden-md-and-down"></div>
    </v-container>
    <wave-effect></wave-effect>
    <v-sheet color="primary" class="d-flex align-center justify-center" style="height: 400px;">
      <div class="white--text text-center mt-6" style="width: 400px;">
        <v-divider class="white mt-4"></v-divider>
        <div class="titleLogoSmall">
          SALT & SEA
        </div>
        <div class="subtitleLogoSmall mt-n4">
          SCUBA
        </div>
        <div class="goBold-2 mt-4">(602) 844-5732</div>
        <div class="goBold-1 mt-6">4139 W Bell Rd. Ste 17</div>
        <div class="goBold-1 mt-2">Phoenix, AZ 85053</div>
        <v-divider class="white mt-6"></v-divider>
        <div class="mt-5 gothic-2">"Empowering Arizona adventurers to embrace the depths with a passion."</div>
      </div>
    </v-sheet>
  </section>
</template>

<script>
import HorizontalLineDivider from '@/components/HorizontalLineDivider'
import QuickInfoAction from '@/components/QuickInfoAction'
import TopCarouselList from '@/components/course/topCarouselList.vue'
import RetailImages from '@/components/retailImages.vue'
import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'
import BentoBoxCard from '@/components/bentoBoxCard.vue'
import OutlinedAutoButton from '@/components/outlinedAutoButton.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'Home',
  components: { WaveEffect, OutlinedAutoButton, BentoBoxCard, OutlinedWhiteButton, RetailImages, TopCarouselList, QuickInfoAction, HorizontalLineDivider },
  data: () => ({
    scrollY: 0,
    design: {
      turtleGif: null,
      turtleLoaded: false
    },
    courses: {
      isEmpty: false,
      isLoading: true,
      data: []
    },
    trip: {
      isEmpty: false,
      isLoading: true,
      data: null
    },
    teamMembers: {
      isEmpty: false,
      isLoading: true,
      data: null
    }
  }),
  methods: {
    doLoadPopularCourses () {
      this.courses.isLoading = true
      this.$services.course.doGetAllPublic().then(courses => {
        if (courses.length === 0) {
          this.courses.isEmpty = true
        }

        this.courses.data = courses
      }).catch(_ => {
        this.courses.isEmpty = true
      }).finally(_ => {
        this.courses.isLoading = false
      })
    },
    doLoadClosestTrip () {
      this.trip.isLoading = true
      this.$services.trip.doGetClosest().then(tripData => {
        if (tripData === null) {
          this.trip.isEmpty = true
        }

        this.trip.data = tripData
      }).catch(_ => {
        this.trip.isEmpty = true
      }).finally(_ => {
        this.trip.isLoading = false
      })
    },
    doLoadTeamMembers () {
      this.trip.isLoading = true
      this.$services.teamMember.doGetAllPublic().then(teamMembers => {
        if (teamMembers === null) {
          this.teamMembers.isEmpty = true
        }

        this.teamMembers.data = teamMembers
      }).catch(_ => {
        this.teamMembers.isEmpty = true
      }).finally(_ => {
        this.teamMembers.isLoading = false
      })
    },
    doLoadTurtle () {
      this.design.turtleGif = new this.$gif({ gif: this.$refs.turtleAnimation.$el, progressbar_height: 0, max_width: 500 })

      this.design.turtleGif.load(() => {
        this.design.turtleLoaded = true
      })
    },
    onLogoIntersect (entries, observer) {
      if (entries[0].isIntersecting) {
        this.$refs.logoAnimation.classList.add('logo-animation-reveal')
      }
    },
    doSetScrollEvent () {
      if (this.design.turtleLoaded || this.design.turtleLoadedMobile) {
        this.scrollY = window.scrollY
      }

      const maxFrames = this.design.turtleGif.get_length()

      if (this.design.turtleGif !== null && this.design.turtleLoaded) {
        let frame = Math.round(this.scrollY / 16)

        frame = frame > maxFrames ? maxFrames : frame

        this.design.turtleGif.move_to(frame)
      }
    },
    doGoToCourse (id) {
      this.$router.push({ name: 'view-course', params: { id: id } })
    },
    doGoToAbout () {
      this.$router.push({ name: 'about' })
    },
    doGoToDiscoverScuba () {
      this.$router.push({ name: 'courses' })
    },
    doGoToEquipment () {
      this.$router.push({ name: 'equipment' })
    }
  },
  created () {
    this.doLoadPopularCourses()
    this.doLoadClosestTrip()
    this.doLoadTeamMembers()
  },
  mounted () {
    this.doLoadTurtle()
    setTimeout(() => this.doScrollToTop(), 10)
    document.addEventListener('scroll', this.doSetScrollEvent)
  },
  destroyed () {
    document.removeEventListener('scroll', this.doSetScrollEvent)
  }
}
</script>

<style scoped lang="scss">
.titleLogo {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 128px;
  letter-spacing: -2px;
}

.subtitleLogo {
  font-family: 'Gothic', sans-serif !important;
  font-size: 64px;
  font-weight: 300;
}

.titleLogoMobile {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 64px !important;
  letter-spacing: -2px;
}

.subtitleLogoMobile {
  font-family: 'Gothic', sans-serif !important;
  font-size: 32px !important;
  font-weight: 300;
}

.titleLogoSmall {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 46px !important;
  letter-spacing: -2px;
}

.subtitleLogoSmall {
  font-family: 'Gothic', sans-serif !important;
  font-size: 22px !important;
  font-weight: 300;
}

.retailInfoButton {
  position: absolute;
  bottom: 35px;
  width: 100%;
  z-index: 5 !important;
}

::v-deep .primaryCourse .v-overlay__content {
  width: 100%;
  height: 100%;
}

::v-deep .teamMemberCard .v-overlay__content {
  width: 100%;
  height: 100%;
}

.background-text {
  background-size: 100%;
  background-position: 50% 50% !important;
  -webkit-background-clip: text !important;
  color: rgba(0,0,0,0.08);
  animation: zoomout-in 5s ease 500ms infinite;
}

.heated-pool {
  background: url(https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/indoor-pool-1-preload.jpg);
}

.store-equipment {
  background: url(https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/store-equipment-1-preload.jpg);
}

.turtleAnimation {
  position: absolute; bottom: -75px; right: 250px;
}

.logo-background-image {
  background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #000, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "Source Sans Pro", sans-serif;
}

.logo-animation-reveal {
  animation: reveal 2000ms ease-in-out forwards 200ms,
  glow 3500ms linear 1000ms;
}

@keyframes reveal {
  80%{
    letter-spacing: 4px;
  }
  100% {
    background-size: 1000% 1000%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #000;
  }
}

// SM
@media screen and (max-width: 959px) {
  ::v-deep .jsgif canvas {
    width: 200px!important;
  }

  .turtleAnimation {
    bottom: -22px !important; right: -160px !important;
  }
}

 // MD
@media screen and (max-width: 1263px) {
  .primaryCourse {
    height: 300px !important;
  }

  .retailInfo {
    padding-bottom: 40px !important;
  }

  .trip-info {
    border-radius: 0 !important;
  }

  .remove-border-radius-md {
    border-radius: 0 !important;
  }
}

@media screen and (min-width: 1264px) {
  .expanded{
    margin-left: initial !important;
    margin-right: initial !important;
  }
}

// LG
@media screen and (max-width: 1903px) {
  .retailInfoButton {
    bottom: 0 !important;
  }
}

</style>
