<template>
  <section>
    <v-btn outlined :block="block" :class="`buttonElement ${buttonClass} white--text `" v-on:mouseenter="doMouseEnter" v-on:mouseleave="doMouseLeave" @click="doClickButton">
      <div class="move-left">
        <slot></slot>
      </div>
      <div class="ml-2" style="width: 10px;">
        <transition name="fade" mode="out-in">
          <div v-if="hovering" key="element">
            <font-awesome-icon :icon="['fas', 'arrow-right-to-bracket']"/>
          </div>
          <div v-else>
            <font-awesome-icon :icon="['fas', 'up-right-from-square']"/>
          </div>
        </transition>
      </div>
    </v-btn>
  </section>
</template>

<script>
export default {
  name: 'outlinedWhiteButton',
  props: {
    buttonClass: {
      type: String
    },
    block: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    hovering: false
  }),
  methods: {
    doMouseEnter () {
      this.hovering = true
    },
    doMouseLeave () {
      this.hovering = false
    },
    doClickButton () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.move-left {
  transition: transform 0.3s ease;
}

/* Apply the hover effect */
.buttonElement:hover .move-left {
  transform: translateX(-4px);
}
</style>
